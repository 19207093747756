import { render, staticRenderFns } from "./NftPicture.vue?vue&type=template&id=0d1f5e9c&scoped=true&"
import script from "./NftPicture.vue?vue&type=script&lang=ts&"
export * from "./NftPicture.vue?vue&type=script&lang=ts&"
import style0 from "./NftPicture.vue?vue&type=style&index=0&id=0d1f5e9c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d1f5e9c",
  null
  
)

export default component.exports
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QImg});
